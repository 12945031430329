import type { LoaderFunctionArgs, MetaFunction } from "@remix-run/node"
import { redirect } from "@remix-run/node"
import { getAuthToken } from "~/utils/session.server"

/**
 * This file is the index route for the root route, "/".
 * That is, this is the default content to render in the <Outlet /> in app/root.tsx.
 * See https://remix.run/docs/en/main/start/tutorial#index-routes
 */

export const meta: MetaFunction = () => {
  return [
    { title: "Fielder" },
    { name: "description", content: "Welcome to Fielder!" },
  ]
}

// this executes for any request to the root of the site
export async function loader({ request }: LoaderFunctionArgs) {
  const authToken = await getAuthToken(request)
  // if any GCP health check probes use root path, return 200 OK
  if (
    request.headers.get("user-agent")?.startsWith("kube-probe/") ||
    request.headers.get("user-agent")?.startsWith("GoogleHC/")
  ) {
    return new Response("OK", { status: 200 })
  } else if (authToken) {
    return redirect("/dashboard")
  } else {
    return redirect("/login")
  }
}

export default function Index() {
  return (
    <div>
      <h1>Fielder</h1>
      <p>Welcome to Fielder!</p>
    </div>
  )
}
